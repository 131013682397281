import React, { useState, useEffect } from "react";
import SearchBar from "./SearchBar";
import SearchCertificateList from "./SearchCertificateList";
import { GoogleSpreadsheet } from "google-spreadsheet";

// Config variables
const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID;
const SHEET_ID = process.env.REACT_APP_SHEET_ID_CERTIFICATES;
const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_CLIENT_EMAIL;
const PRIVATE_KEY = process.env.REACT_APP_GOOGLE_SERVICE_PRIVATE_KEY;

const Search = (props) => {
  const [input, setInput] = useState("");
  const [inputDisabled, setinputDisabled] = useState(true);
  const [certificateListDefault, setCertificateListDefault] = useState();
  const [certificateList, setCertificateList] = useState();

  const fetchData = async () => {
    const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      // loads document properties and worksheets
      await doc.loadInfo();

      const sheet = doc.sheetsById[SHEET_ID];
      const data = await sheet.getRows();
      setCertificateList(data);
      setCertificateListDefault(data);
      setinputDisabled(false);
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const updateInput = async (input) => {
    const filtered = certificateListDefault.filter((certificate) => {
      return (
        certificate.product.toLowerCase().includes(input.toLowerCase()) ||
        certificate.company.toLowerCase().includes(input.toLowerCase()) ||
        certificate.certification.toLowerCase().includes(input.toLowerCase())
      );
    });
    setInput(input);
    setCertificateList(filtered);
  };

  useEffect(() => {
    fetchData();
    document.title = props.title || "";
  }, [props.title]);

  return (
    <div className="px-4 py-5 my-5 text-center">
      <h1 className="display-5 fw-bold">Directorio de Certificados</h1>
      <div className="col-lg-6 mx-auto">
        <p className="lead mb-4">
          Digite el nombre de la empresa certificada por el CPR del ICIPC
        </p>
        <SearchBar
          input={input}
          onChange={updateInput}
          disabled={inputDisabled}
        />
      </div>
      {input.length > 0 && (
        <SearchCertificateList certificateList={certificateList} />
      )}
    </div>
  );
};

export default Search;
