import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";

const Verify = (props) => {
  const [search, setSearch] = useState("");

  useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);

  return (
    <div className="px-4 py-5 my-5 text-center">
      <h1 className="display-5 fw-bold">
        Verificación de Certificado
      </h1>
      <div className="col-lg-6 mx-auto">
        <p className="lead mb-4">
          Los procesos certificados por el CPR del ICIPC tienen un código único. Por favor digite el código en el siguiente campo.
        </p>
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Código LOOP ICIPC o código de barras del producto"
            aria-label="loopCode"
            aria-describedby="basic-addon1"
            onChange={(event) => setSearch(event.target.value)}
          />
        </div>
        <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
          <Route
            render={({ history }) => (
              <button
                type="button"
                className="btn btn-primary btn-lg px-4 me-sm-3"
                onClick={() => {
                  history.push("/verify/" + search);
                }}
              >
                Verificar
              </button>
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default Verify;
